import React from "react"
import {
  Hero,
  WhatIsShabbath,
  ShabbathSet,
  ShabbathSetContent,
  ShabbathSetBanner,
  RelatedVideo,
  DonateBlock,
} from 'components/common'
import { PapersArchiveIndexBlock } from 'components/papers'
import { ReviewsIndexBlock } from 'components/reviews'
import { withMainLayout } from 'components/layouts'
import SEO from 'components/seo'

const IndexPage = () => (
  <div style={{marginTop: '-68px'}}>
    <SEO
      title='Главная страница'
    />
    <Hero />
    <WhatIsShabbath />
    <ShabbathSet />
    <ShabbathSetContent />
    <ReviewsIndexBlock />
    <ShabbathSetBanner />
    <RelatedVideo />
    <PapersArchiveIndexBlock />
    <DonateBlock />
  </div>
)

export default withMainLayout(IndexPage)
